import { Component, OnInit, Inject, AfterViewInit, ViewEncapsulation, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from "@angular/router";
import { MatDialogRef, MatTableDataSource, MatDialog, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { PageEvent } from '@angular/material';

import { LocalSaveService } from '../local-save.service';
import { ServicesService } from '../services.service';
import { ComponentPageTitle } from '../page-title/page-title';

import { NodeEditor, Engine } from 'rete';
import * as ConnectionPlugin from 'rete-connection-plugin';
import * as VueRenderPlugin from 'rete-vue-render-plugin';
import * as ContextMenuPlugin from 'rete-context-menu-plugin';
import { ingresoNodo } from '../nodos/nodos.component';
import { Board } from './structures/board';
import { ingresoComandos } from '../comandos/comandos.component';
import { deleteElement } from '../delete/eliminar-element.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Generator } from './structures/generator';
import { SelectionModel } from '@angular/cdk/collections';
import { read } from 'fs';


@Component({
	selector: 'drag-drop',
	templateUrl: './drag-drop.component.html',
	styleUrls: ['./drag-drop.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class DragDropComponent implements OnInit, AfterViewInit {
	codigo_bot: number;
	editor;
	engine;
	board;
	is_crosshair: boolean = false;
	action: string = "";
	statebot;
	loadingdrag: boolean = false;
	ready = false;

	constructor(private componentPageTitle: ComponentPageTitle, private router: Router, private route: ActivatedRoute, private localSave: LocalSaveService, private dialog: MatDialog, private http: HttpClient, private services: ServicesService, private snackBar: MatSnackBar) {

		router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				if (event.url != '/home/drag/drop') {
					this.loadingdrag = false;
				}
				if (!this.localSave.validate('generate') && event.url != "/home/drag-drop") {
					this.localSave.delete('nombre_bot_seleccionado');
				}
			}
		});

	}

	@ViewChild('nodeEditor') el: ElementRef;


	async ngAfterViewInit() {

		const container = this.el.nativeElement;

		this.editor = new NodeEditor('demo@0.2.0', container);
		this.board = new Board(this.editor);
		this.editor.use(ConnectionPlugin);
		this.editor.use(VueRenderPlugin);

		this.engine = new Engine('demo@0.2.0');

		this.board.getComponents().map(c => {
			this.editor.register(c);
			this.engine.register(c);
		});

		this.editor.on('process nodecreated noderemoved', async () => {
			await this.engine.abort();
		});

		this.editor.on('connectioncreated connectionremoved', async () => {
			if(this.ready)
			{
				this.saveJson();
			}
			await this.engine.abort();
		});

		this.editor.use(ContextMenuPlugin, {
			searchBar: false,
			delay: 100,
			allocate(component) {
				return ['Submenu'];
			},
			rename(component) {
				return component.name;
			}
		});

		this.editor.on('modificarnodo', modified_node => {
			if (modified_node.data.type === "inicio" || modified_node.data.type == "security"  || modified_node.data.type == "security") {
				this.snackBar.open("Este tipo de nodo no se puede modificar", '', { duration: 3000 });
			} else {
				switch (modified_node.name) {
					case "texto":
						this.services.getNode({ codigo: modified_node.data.code }).subscribe(data => {
							let node = data[0];
							let dialogRef = this.dialog.open(ingresoNodo, {
								width: '90%',
								height: '90%',
								disableClose: true,
								data: { node }
							});
							dialogRef.afterClosed().subscribe(result => {
								if (result) {
									if (dialogRef.componentInstance.wasModified) {
										modified_node.data.name = dialogRef.componentInstance.nodoForm.value.nombre;
										modified_node.update();
										this.saveJson();
									}
								}
							});
						});
						break;
					case "seleccion":
						this.services.getNode({ codigo: modified_node.data.code }).subscribe(data => {
							let node = data[0];
							let dialogRef = this.dialog.open(ingresoNodo, {
								width: '90%',
								height: '90%',
								disableClose: true,
								data: { node }
							});
							dialogRef.afterClosed().subscribe(result => {
								if (result) {
									if (dialogRef.componentInstance.wasModified) {
										modified_node.data.name = dialogRef.componentInstance.nodoForm.value.nombre;
										this.services.getOutputsByNode({ codigo: dialogRef.componentInstance.wasModified }).subscribe(data => {
											modified_node.data.outputs = this.convertToArray(data, "menu");
											this.updateOutputs(modified_node);
											this.saveJson();
										});
									}
								}
							});
						});
						break;
					case "menu":
						this.services.getNode({ codigo: modified_node.data.code }).subscribe(data => {
							let node = data[0];
							let dialogRef = this.dialog.open(ingresoNodo, {
								width: '90%',
								height: '90%',
								disableClose: true,
								data: { node }
							});
							dialogRef.afterClosed().subscribe(result => {
								if (result) {
									if (dialogRef.componentInstance.wasModified) {
										modified_node.data.name = dialogRef.componentInstance.nodoForm.value.nombre;
										this.services.getOutputsByNode({ codigo: dialogRef.componentInstance.wasModified }).subscribe(data => {
											modified_node.data.outputs = this.convertToArray(data, "menu");
											this.updateOutputs(modified_node);
											this.saveJson();
										});
									}
								}
							});
						});
						break;
					case "quickresponse":
						this.services.getNode({ codigo: modified_node.data.code }).subscribe(data => {
							let node = data[0];
							let dialogRef = this.dialog.open(ingresoNodo, {
								width: '90%',
								height: '90%',
								disableClose: true,
								data: { node }
							});
							dialogRef.afterClosed().subscribe(result => {
								if (result) {
									if (dialogRef.componentInstance.wasModified) {
										modified_node.data.name = dialogRef.componentInstance.nodoForm.value.nombre;
										this.services.getOutputsByNode({ codigo: dialogRef.componentInstance.wasModified }).subscribe(data => {
											modified_node.data.outputs = this.convertToArray(data, "quickresponse");
											this.updateOutputs(modified_node);
											this.saveJson();
										});
									}
								}
							});
						});
						break;
					case "template":
							this.services.getNode({ codigo: modified_node.data.code }).subscribe(data => {
								let node = data[0];
								let dialogRef = this.dialog.open(ingresoTemplate, {
									width: '90%',
									height: '90%',
									disableClose: true,
									data: {
										bot: this.codigo_bot,
										template: node 
									}
								});
								dialogRef.afterClosed().subscribe(result => {
									if (result) {
										if (dialogRef.componentInstance.wasModified) {
											modified_node.data.name = dialogRef.componentInstance.nodoForm.value.nombre;
											this.services.getOutputsByNode({ codigo: dialogRef.componentInstance.wasModified }).subscribe(data => {
												modified_node.data.outputs = this.convertToArray(data, "template");
												this.updateOutputs(modified_node);
												this.saveJson();
											});
										}
									}
								});
							});
							break;
					case "comando":
						this.services.getNode({ codigo: modified_node.data.code }).subscribe(data => {
							let comando = data[0];
							let dialogRef = this.dialog.open(ingresoComandos, {
								disableClose: true,
								width: '90%',
								height: '90%',
								data: { comando }
							});
							dialogRef.afterClosed().subscribe(result => {
								if (result) {
									if (dialogRef.componentInstance.wasModified) {
										modified_node.data.name = dialogRef.componentInstance.comandoForm.value.nombre;
										modified_node.update();
										this.saveJson();
									}
								}
							});
						});
						break;
					case "decision":
						this.services.getNode({ codigo: modified_node.data.code }).subscribe(data => {
							let decision = data[0];
							let dialogRef = this.dialog.open(ingresoDecision, {
								width: '90%',
								height: '90%',
								disableClose: true,
								data: { decision: decision }
							});
							dialogRef.afterClosed().subscribe(result => {
								if (result) {
									if (dialogRef.componentInstance.wasModified) {
										modified_node.data.name = dialogRef.componentInstance.decisionForm.value.nombre;
										this.services.getConditions({ codigo: dialogRef.componentInstance.wasModified }).subscribe(data => {
											modified_node.data.outputs = this.convertToArray(data, "decision");
											this.updateOutputs(modified_node);
											this.saveJson();
										});
									}
								}
							});
						});
						break;
				}
			}
		});

		this.editor.on('copiarnodo', node => {
			if (node.data.type === "inicio") {
				this.snackBar.open("Este tipo de nodo no se puede copiar", '', { duration: 3000 });
			} else {
				this.services.copyNode({ codigo_nodo: node.data.code, bot: node.data.bot }).subscribe(async data_copy => {
					const { name, data, meta, position: [x, y] } = node;
					let temp_data = { ...data };
					temp_data.code = data_copy[0].resultado;
					const component = this.editor.components.get(name);
					let newNode = await component.createNode(temp_data);
					newNode.meta = meta;
					newNode.position[0] = x + 10;
					newNode.position[1] = y + 10;
					this.editor.addNode(newNode);
					this.saveJson();
				});
			}
		});

		this.editor.on('eliminarnodo', node => {
			if (node.data.type === "inicio") {
				this.snackBar.open("Este tipo de nodo no se puede eliminar", '', { duration: 3000 });
			} else {
				let dialogRef = this.dialog.open(deleteElement, {
					disableClose: true,
					data: { node: { nombre: node.data.name } }
				});
				dialogRef.afterClosed().subscribe(result => {
					if (result) {
						this.services.deleteNode({ codigo: node.data.code }).subscribe(async data => {
							this.editor.removeNode(node);
							this.saveJson();
						});
					}
				});
			}
		});

		this.editor.on('click', event => {
			if (this.is_crosshair) {
				this.is_crosshair = false;
				this.board.setPositions(event.e.screenX, event.e.screenY);
				if (this.action == "add") {
					let dialogRef = this.dialog.open(ingresoNodo, {
						width: '90%',
						height: '90%',
						disableClose: true,
						data: {
							board: this.board,
							bot: this.codigo_bot
						}
					});
					dialogRef.afterClosed().subscribe(result => {
						if (result) {
							this.saveJson();
						}
					});
				} else if (this.action == "decision") {
					let dialogRef = this.dialog.open(ingresoDecision, {
						width: '90%',
						height: '90%',
						disableClose: true,
						data: {
							board: this.board,
							bot: this.codigo_bot,
							decision: null
						}
					});
					dialogRef.afterClosed().subscribe(result => {
						if (result) {
							this.saveJson();
						}
					});
				} else if (this.action == "template") {
					let dialogRef = this.dialog.open(ingresoTemplate, {
						width: '90%',
						height: '90%',
						disableClose: true,
						data: {
							board: this.board,
							bot: this.codigo_bot,
							template: null
						}
					});
					dialogRef.afterClosed().subscribe(result => {
						if (result) {
							this.saveJson();
						}
					});
				} else if (this.action == "load") {
					let dialogRef = this.dialog.open(cargarNodo, {
						width: '90%',
						height: '90%',
						disableClose: true,
						data: {
							board: this.board,
							bot: this.codigo_bot
						}
					});
					dialogRef.afterClosed().subscribe(result => {
						if (result) {
							this.saveJson();
						}
					});
				}
				else if(this.action == "security")
				{
					this.board.addSecurity("Nodo Seguridad SMS", this.codigo_bot, "security");
					this.saveJson();
				}
				else if(this.action == "dpi")
				{
					this.board.addDpi("Nodo DPI", this.codigo_bot, "dpi");
					this.saveJson();
				}
			}
		});

		this.editor.view.resize();
		this.editor.trigger('process');

		this.editor.on('zoom', ({ source }) => {
			return source !== 'dblclick';
		});

	}

	async updateOutputs(modified_node) {
		const { name, data, meta, position: [x, y] } = modified_node;
		const component = this.editor.components.get(name);
		let newNode = await component.createNode(data);
		newNode.meta = meta;
		newNode.position[0] = x;
		newNode.position[1] = y;
		this.editor.addNode(newNode);
		let input_modified = modified_node.inputs.get("entrada");
		let input_new = newNode.inputs.get("entrada");
		for (let i = 0; i < input_modified.connections.length; i++) {
			let out = input_modified.connections[0].output;
			this.editor.removeConnection(input_modified.connections[0]);
			this.editor.connect(out, input_new);
		}
		for (let iterator = modified_node.outputs.values(), r; !(r = iterator.next()).done;) {
			if (newNode.data.outputs.includes(r.value.name)) {
				let output_new = newNode.outputs.get(r.value.key);
				for (let i = 0; i < r.value.connections.length; i++) {
					this.editor.connect(output_new, r.value.connections[i].input);
				}
			}
		}
		this.editor.selectNode(newNode);
		this.editor.removeNode(modified_node);
	}

	convertToArray(data, type) {
		let array: string[] = [];
		if (type != "decision") {
			for (let out of data) {
				array.push(out.parametro);
			}
		} else {
			for (let out of data) {
				array.push(out.condicion1 + " " + out.signo_relacional + " " + out.condicion2);
			}
		}
		return array;
	}

	ngOnInit() {
		this.statebot = this.localSave.get('status_bot_seleccionado');
		this.reload();
	}

	reload() {
		this.loadingdrag = true;
		this.codigo_bot = this.localSave.get("codigo_bot_seleccionado");
		if (this.codigo_bot != -1) {
			this.services.getBot({ codigo: this.codigo_bot }).subscribe(async data => {
				this.componentPageTitle.title = data[0].nombre;
				if (!(data[0].json_rete == "{}")) {
					await this.editor.fromJSON(JSON.parse(data[0].json_rete));
					this.loadingdrag = false;
					this.ready = true;
				} else {
					this.board.addInicio(data[0].nombre, this.codigo_bot, "inicio");
					this.loadingdrag = false;
					this.ready = true;
				}
			});
		} else {
			this.loadingdrag = false;
			this.snackBar.open("No se ha seleccionado ningún nodo", '', { duration: 3000 });
		}
	}

	@HostListener('click', ['$event'])
	onClick(evento) {
		let elemento = evento.srcElement.id;
		if (elemento.includes("boton")) {
			let codigo = elemento.replace(/\D/g, "");
			let boton = document.getElementById("boton" + codigo);
			let node = this.editor.nodes.find(x => x.id == codigo);
			if (!node.data.collapse) {
				node.data.posX = node.position[0];
				node.data.posY = node.position[1];
				if (this.min(codigo)) {
					node.data.collapse = true;
					boton.innerText = "add";
				}
			} else {
				if (this.max(codigo, codigo)) {
					node.data.collapse = false;
					boton.innerText = "remove";
					for (let iterator = this.editor.view.connections.values(), r; !(r = iterator.next()).done;) {
						this.editor.trigger('updateconnection', {
							el: r.value.el,
							connection: r.value.connection,
							points: r.value.getPoints()
						});
					}
				}
			}
		}
	}

	private min(codigo) {
		let colapso = false;
		let node = this.editor.toJSON().nodes[codigo];
		for (var out in node.outputs) {
			for (var o of node.outputs[out].connections) {
				this.min(o.node);
				document.getElementById("contenedor" + o.node).classList.add("invisible");
				colapso = true;
			}
		}
		let outputs = document.getElementsByClassName("contenedor" + codigo);
		for (var i = 0; i < outputs.length; i++) {
			outputs[i].classList.add("invisible");
		}
		return colapso;
	}

	private async max(codigoInicial, codigo) {
		let colapso = false;
		let node = this.editor.toJSON().nodes[codigo];
		for (const out in node.outputs) {
			for (var o of node.outputs[out].connections) {
				let boton = document.getElementById("boton" + o.node);
				let node = this.editor.nodes.find(x => x.id == o.node);
				if (boton.innerText == "remove") {
					let node_inicial = this.editor.nodes.find(x => x.id == codigoInicial);
					node.position[0] += node_inicial.position[0] - node_inicial.data.posX;
					node.position[1] += node_inicial.position[1] - node_inicial.data.posY;
					this.max(codigoInicial, o.node);
					let node_style = this.editor.view.nodes.get(node);
					node_style.el.style.transform = "translate(" + node.position[0] + "px," + node.position[1] + "px)";
				}
				document.getElementById("contenedor" + o.node).classList.remove("invisible");
				colapso = true;
			}
		}
		let outputs = document.getElementsByClassName("contenedor" + codigo);
		for (var i = 0; i < outputs.length; i++) {
			outputs[i].classList.remove("invisible");
		}
		return colapso;
	}

	doAction(event) {
		if (event == "save") {
			this.saveJson();
		} else if (event == "generate") {
			this.loadingdrag = true;
			var generator = new Generator(this.router, this.editor, this.services, this.localSave, this.snackBar);
			generator.start();
		} else {
			this.is_crosshair = true;
			this.snackBar.open("Seleccione el punto donde quiere insertar los nodos", '', { duration: 3000 });
			this.action = event;
		}
	}

	async saveJson() {
		const json = this.editor.toJSON();
		const count = Object.keys(json.nodes).length;
		this.services.updateBotJson({ json: JSON.stringify(json), nodos: count, codigo: this.codigo_bot }).subscribe(async data => {
			await this.services.setBotState([this.localSave.get('codigo_bot_seleccionado'), 2]).subscribe(data => {
				this.snackBar.open("Se guardo el bot de manera correcta", '', { duration: 3000 });
			});
		});
	}
}

@Component({
	selector: 'cargar-nodo',
	templateUrl: 'cargar-nodo.html',
	styles: ['.example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center !important;} table { width: 100%;} .addButton { float: right; margin-top: -3%;margin-bottom: 3%;background-color:#5BC500;}']
})

export class cargarNodo {

	displayedColumns = ['nombre', 'descripcion', 'tipo_nodo', 'seleccionar'];
	selections: SelectionModel<any>[] = [];
	nodes = new MatTableDataSource([]);
	filter_search = "";
	filter_type = "";
	isLoadingResults = false;
	pageEvent: PageEvent;
	nodeCount = 0;
	pageIndex = 0;
	board;
	bot: number;

	constructor(public dialogRef: MatDialogRef<cargarNodo>, public componentPageTitle: ComponentPageTitle, private router: Router, private localSave: LocalSaveService, public dialog: MatDialog, private http: HttpClient, public services: ServicesService, @Inject(MAT_DIALOG_DATA) private data: any) {
		this.board = this.data.board;
		this.bot = this.data.bot;
	}

	ngOnInit() {
		this.reload();
	}

	reload() {
		this.isLoadingResults = true;
		if (typeof (this.pageEvent) != 'undefined') {
			this.pageIndex = this.pageEvent.pageIndex;
		}
		if (!this.selections[this.pageIndex]) {
			this.selections[this.pageIndex] = new SelectionModel<any>(true, []);
		}
		var filters = [this.filter_search, this.pageIndex, this.filter_type];
		this.services.getNodes({ filters: filters, todos: 1 }).subscribe(data => {
			this.nodes = new MatTableDataSource(data);
			console.log(data);
			this.services.getNodesCount({ filters: filters, todos: 1 }).subscribe(data => {
				this.nodeCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}



	async cargarNodos() {
		let counter = 0;
		for (let selection of this.selections) {
			for (let codigo of selection.selected) {
				await this.procesarCarga(codigo, counter);
				counter++;
			}
		}
		this.dialogRef.close(true);
	}

	procesarCarga(codigo, counter) {
		return new Promise((resolve, reject) => {
			this.services.copyNode({ codigo_nodo: codigo, bot: this.bot }).subscribe(data => {
				this.services.getNode({ codigo: codigo }).subscribe(data_node => {
					let node = data_node[0];
					let tipo = node.tipo_nodo.toLowerCase();
					if (tipo === 'menu') {
						this.services.getOutputsByNode({ codigo: codigo }).subscribe(outputs => {
							this.board.addMenu(node.nombre, data[0].resultado, this.bot, counter, tipo, outputs);
							resolve();
						});
					} else if (tipo === 'quickresponse') {
						this.services.getOutputsByNode({ codigo: codigo }).subscribe(outputs => {
							this.board.addQuick(node.nombre, codigo, data[0].resultado, this.bot, tipo, outputs);
							resolve();
						});
					}
					else if (tipo === 'template') {
						this.services.getOutputsByNode({ codigo: codigo }).subscribe(outputs => {
							this.board.addTemplate(node.nombre, codigo, data[0].resultado, this.bot, tipo, outputs);
							resolve();
						});
					} else if (tipo === 'texto' || tipo === 'multimedia' || tipo === 'pedir_numero') {
						this.board.addTexto(node.nombre, data[0].resultado, this.bot, counter, tipo);
						resolve();
					} else if (tipo === 'comando') {
						this.board.addComando(node.nombre, data[0].resultado, this.bot, counter);
						resolve();
					} else {
						resolve();
					}
				});
			});
		});
	}

	procesarMenu(codigo) {

	}



	isAllSelected() {
		const numSelected = this.selections[this.pageIndex].selected.length;
		const numRows = this.nodes.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ?
			this.selections[this.pageIndex].clear() :
			this.nodes.data.forEach(row => this.selections[this.pageIndex].select(row.codigo_nodo));
	}

	onNoClick(): void {
		this.dialogRef.close(false);
	}

	search() {
		this.filter_search = this.filter_search.trim();
		this.reload();
	}

}

@Component({
	selector: 'ingreso-decision',
	templateUrl: 'ingreso-decision.html',
	styles: ['::ng-deep.mat-tab-body-wrapper {height:100% !important} .example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center;} table { width: 100%;}']
})

export class ingresoDecision {

	decisionActiva = -1;
	nombreActivo = "";
	comandosvariables = [];
	variablesdisponibles = [];
	variablescomandos = [];
	decisionForm = new FormGroup({
		codigo_nodo: new FormControl(''),
		nombre: new FormControl('', Validators.required),
		descripcion: new FormControl('', Validators.required),
		tipo_nodo: new FormControl(''),
		codigo_tipo_nodo: new FormControl(''),
		im: new FormControl(1),
		bot: new FormControl()
	});

	conditionForm = new FormGroup({
		condicion1: new FormControl('', Validators.required),
		signo_relacional: new FormControl('', Validators.required),
		condicion2: new FormControl('', Validators.required),
		tipo_insercion: new FormControl('', Validators.required),
		posicion: new FormControl('', Validators.required),
	});

	condicionColumns = ["no", "condicion1", "signo_relacional", "condicion2", "accion"];

	condiciones = new MatTableDataSource([]);

	wasModified;
	board;
	decision;
	bigger;
	highlightedRows = [];
	isEditable = false;
	isLoadingResults = false;
	selected = 0;
	isMiddle: boolean = false;

	constructor(private dialogRef: MatDialogRef<ingresoDecision>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private services: ServicesService, private dialog: MatDialog) {
		this.board = this.data.board;
		this.decision = this.data.decision;
		this.decisionForm.controls.bot.setValue(this.data.bot);
		this.conditionForm.controls.posicion.disable();
		if (typeof (this.decision) != 'undefined' && this.decision!=null) {
			this.getcomandosvariables();
			this.decisionForm.patchValue(this.decision);
			this.isEditable = true;
			this.decisionActiva = this.decision.codigo_nodo;
			this.nombreActivo = this.decision.nombre;
			this.reload();
		}
		this.services.getTypeByName({ nombre: "decision" }).subscribe(data => {
			this.decisionForm.controls.codigo_tipo_nodo.setValue(data[0].codigo_tipo_nodo);
			this.decisionForm.controls.tipo_nodo.setValue(data[0].nombre);
		});
	}

	getcomandosvariables(){
		this.services.getinputspicker({ codigo_bot: this.data.decision.bot }).subscribe(data => {
			this.comandosvariables = data.encabezados;
			this.variablescomandos = data.variables;
		});
	}

	filtervariables(nombre) {
		this.variablesdisponibles = [];
		this.variablescomandos.map((variable) => {
			if (variable.nombre == nombre) {
				this.variablesdisponibles.push(variable)
			}
		});
		console.log(this.variablesdisponibles);
	}

	addvariable(parametro,condicion){
		if(condicion == 1){
			this.conditionForm.controls.condicion1.setValue('_'+parametro); 
		}else if(condicion == 2){
			this.conditionForm.controls.condicion2.setValue('_'+parametro); 
		}
	}

	reload() {
		this.isLoadingResults = true;
		var params = { codigo: this.decisionActiva };
		this.services.getConditions(params).subscribe(data => {
			this.condiciones = new MatTableDataSource(data);
			this.bigger = this.condiciones.data.length - 1;
			this.isLoadingResults = false;
		});
	}

	saveDecision() {
		this.decisionForm.value.tipo_consumo = 0;
		//console.log(this.templateForm.value);
		this.services.insertNode(this.decisionForm.value).subscribe(data => {

			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.decisionActiva = resultado;
				this.nombreActivo = this.decisionForm.value.nombre;
				var params = { nodo: this.decisionActiva, condiciones: this.condiciones.data };
				this.services.insertDecisions(params).subscribe(data => {
					if (typeof (this.board) != 'undefined') {
						let tipo = this.decisionForm.value.tipo_nodo;
						if (tipo === 'decision') {
							this.services.getConditions({ codigo: this.decisionActiva }).subscribe(outputs => {
								this.board.addDecision(this.decisionForm.value.nombre, this.decisionActiva, this.decisionForm.value.bot, 0, tipo, outputs);
							});
						}
					}
					this.snackBar.open("El Nodo decisión " + this.nombreActivo + " se guardó correctamente", '', { duration: 3000 });
					this.dialogRef.close(true);
				});
			}
			else {
				this.snackBar.open("Nodo ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	modifyDecision() {
		this.decisionForm.value.tipo_consumo = 0;
		this.services.updateNode(this.decisionForm.value).subscribe(data => {
			//console.log(data);
			this.wasModified = data[0].resultado;
			if (this.wasModified != -1) {
				this.nombreActivo = this.decisionForm.value.nombre;
				var params = { nodo: this.wasModified, condiciones: this.condiciones.data };
				this.services.insertDecisions(params).subscribe(data => {
					this.dialogRef.close(true);
				});
			}
			else {
				this.snackBar.open("Nodo ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	addCondition() {
		let condicion = this.conditionForm.value;
		if (this.validateCondition(condicion.condicion1)) {
			this.snackBar.open("La condicion 1 no tiene un valor que es aceptado", '', { duration: 3000 });
		} else if (this.validateCondition(condicion.condicion2)) {
			this.snackBar.open("La condicion 2 no tiene un valor que es aceptado", '', { duration: 3000 });
		} else {
			if (condicion.tipo_insercion === "inicio") {
				this.condiciones.data.splice(0, 0, condicion);
				this.condiciones = new MatTableDataSource(this.condiciones.data);
			} else if (condicion.tipo_insercion === "medio") {
				this.condiciones.data.splice(condicion.posicion - 1, 0, condicion);
				this.condiciones = new MatTableDataSource(this.condiciones.data);
			} else if (condicion.tipo_insercion === "final") {
				this.condiciones.data.push(condicion);
				this.condiciones = new MatTableDataSource(this.condiciones.data);
			}
			this.conditionForm.reset();
		}
	}

	validateCondition(condition): boolean {
		if (/^([a-zA-Z_]\w*)$/.test(condition)) {
			return false;
		} else if (/^(\d+\.?\d*|\.\d+)$/.test(condition)) {
			return false;
		} else if (/"([^']*)"/.test(condition)) {
			return false;
		}
		return true;
	}

	editCondition(event, index) {
		let below = this.returnBelow();
		let above = this.returnAbove();
		if (this.highlightedRows.length != 0) {
			if (event == "before") {
				if (above == -1) {
					this.snackBar.open("No se puede desplazar hacia arriba", '', { duration: 3000 });
				} else {
					let element = this.condiciones.data[above];
					this.condiciones.data.splice(above, 1);
					this.condiciones.data.splice(below - 1, 0, element);
					this.condiciones = new MatTableDataSource(this.condiciones.data);
				}
			} else if (event == "after") {
				if (below == this.condiciones.data.length) {
					this.snackBar.open("No se puede desplazar hacia abajo", '', { duration: 3000 });
				} else {
					let element = this.condiciones.data[below];
					this.condiciones.data.splice(below, 1);
					this.condiciones.data.splice(above + 1, 0, element);
					this.condiciones = new MatTableDataSource(this.condiciones.data);
				}
			}
		} else {
			this.snackBar.open("Debe seleccionar al menos una accion", '', { duration: 3000 });
		}
	}

	deleteCondition(index) {
		if (this.condiciones.data.length == 1) {
			this.snackBar.open("Los comandos siempre deben de tener al menos una accion", '', { duration: 3000 });
		} else {
			this.condiciones.data.splice(index, 1);
			this.condiciones = new MatTableDataSource(this.condiciones.data);
		}
		this.bigger = this.condiciones.data.length - 1;
	}

	returnBelow() {
		let first = this.condiciones.data.indexOf(this.highlightedRows[0]);
		let last = this.condiciones.data.indexOf(this.highlightedRows[this.highlightedRows.length - 1]);
		if (first > last) {
			return first + 1;
		} else {
			return last + 1;
		}
	}

	returnAbove() {
		let first = this.condiciones.data.indexOf(this.highlightedRows[0]);
		let last = this.condiciones.data.indexOf(this.highlightedRows[this.highlightedRows.length - 1]);
		if (first > last) {
			return last - 1;
		} else {
			return first - 1;
		}
	}

	selectActionRow(element) {
		if (this.condiciones.data.length !== 1) {
			let currentIndex = this.condiciones.data.indexOf(element);
			let above = this.condiciones.data[currentIndex + 1];
			let below = this.condiciones.data[currentIndex - 1];
			if (this.highlightedRows.includes(element)) {
				if (this.highlightedRows.includes(above) && this.highlightedRows.includes(below)) {
					this.highlightedRows = [];
				} else {
					this.highlightedRows.splice(this.highlightedRows.indexOf(element), 1);
				}
			} else {
				if (this.highlightedRows.includes(above)) {
					this.highlightedRows.push(element);
				}
				else if (this.highlightedRows.includes(below)) {
					this.highlightedRows.push(element);
				} else {
					this.highlightedRows = [];
					this.highlightedRows.push(element);
				}
			}
		}
	}

	updatePosition(evt) {
		this.conditionForm.controls.tipo_insercion.setValue(evt.value);
		if (this.conditionForm.value.tipo_insercion === "medio") {
			this.isMiddle = true;
			this.conditionForm.controls.posicion.enable();
		} else if (this.isMiddle && this.conditionForm.value.tipo_insercion !== "medio") {
			this.isMiddle = false;
			this.conditionForm.controls.posicion.disable();
		}
	}

	tabsel(evt): void {
		this.selected = evt;
	}

	onNoClick(event): void {
		this.dialogRef.close(event);
	}

}

@Component({
	selector: 'ingreso-template',
	templateUrl: 'ingreso-template.html',
	styles: ['::ng-deep.mat-tab-body-wrapper {height:100% !important} .example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center;} table { width: 100%;}']
})
export class ingresoTemplate {

	nodoActivo = -1;
	templateActivo = -1;
	nombreActivo = "";
	comandosvariables = [];
	variablesdisponibles = [];
	variablescomandos = [];
	nodoForm = new FormGroup({
		nombre: new FormControl('', Validators.required),
		descripcion: new FormControl('', Validators.required),
		codigo_tipo_nodo: new FormControl(29),
		im: new FormControl(1),
		bot: new FormControl()
	});

	templateForm = new FormGroup({
		codigo_nodo: new FormControl(''),
		codigo_template: new FormControl(''),
		titulo: new FormControl('', Validators.required),
		subtitulo: new FormControl('', Validators.required),
		url_img: new FormControl('', Validators.required)
	});

	buttonForm = new FormGroup({
		texto: new FormControl('', Validators.required),
		link: new FormControl('')
	});

	botonColumns = ["texto", "link", "accion"]
	templateColumns = ["titulo", "subtitulo", "url_img", "accion"];

	templates = new MatTableDataSource([]);
	template_buttons = new MatTableDataSource([]);

	wasModified;
	board;
	template;
	bigger;
	highlightedRows = [];
	isEditable = false;
	isLoadingResults = false;
	selected = 0;
	isMiddle: boolean = false;
	templateCount = 0;
	buttonCount = 0;
	outputs = [];
	nombreTemplateActivo = "";

	constructor(private dialogRef: MatDialogRef<ingresoTemplate>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private services: ServicesService, private dialog: MatDialog) {
		this.board = this.data.board;
		this.template = this.data.template;
		this.nodoForm.controls.bot.setValue(this.data.bot);
		//this.conditionForm.controls.posicion.disable();
		if (typeof (this.template) != 'undefined' && this.template!=null) {
			
			//SE ESTA MODIFICANDO
			this.templateForm.patchValue(this.template);
			this.isEditable = true;
			this.templateActivo = this.template.codigo_nodo;
			this.nombreActivo = this.template.nombre;
			this.reload();
		}
		
	}

	getErrorMessage(element) {
		if (element.hasError('required')) {
			return "Campo requerido";
		} else if (element.hasError('maxlength')) {
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		} /* else if (element.hasError('pattern')) {
			return "Solo número enteros y positivos menores o iguales a 10 digitos";
		} */
	}

	reload() {
		this.isLoadingResults = true;
		this.services.getTemplatesNode({codigo_nodo: this.nodoActivo}).subscribe(data => {
			console.log(data);
			this.templates = new MatTableDataSource(data);
			this.templateCount = data.length;
			if(this.templateActivo != -1)
			{
				this.services.getButtonsTemplate({codigo_template: this.templateActivo}).subscribe(data2 => {
					console.log(data2);
					this.template_buttons = new MatTableDataSource(data2);
					this.buttonCount = data2.length;
				});
			}else
			{	
				this.template_buttons = new MatTableDataSource([]);
			}
			this.isLoadingResults = false;
		});
	}

	saveNodeTemplate() {
		console.log(this.nodoForm.value);
		let node = this.nodoForm.value;
		node["tipo_consumo"] = 0;
		this.services.insertNode(this.nodoForm.value).subscribe(data => {
			console.log(data);
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.nodoActivo = resultado;
				this.nombreActivo = this.nodoForm.value.nombre;
				this.tabsel(1);
			}
			else {
				this.snackBar.open("Nodo ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}
	agregarBoton()
	{
		if(this.templateActivo == -1)
		{
			this.templateForm.controls.codigo_nodo.setValue(this.nodoActivo); 
			this.services.insertTemplate(this.templateForm.value).subscribe(data => {
				this.templateActivo = data[0].resultado;
				this.nombreTemplateActivo = this.templateForm.value.titulo;
				let params = this.buttonForm.value;
				params["codigo_template"] = this.templateActivo;
				this.services.insertButtonTemplate(params).subscribe(data2 => {
					console.log(this.buttonForm.value);
					if(this.buttonForm.value.link == null)
					{
						console.log("ENTRO IF");
						var params2 = {frases: [this.buttonForm.value.texto], nodo: this.nodoActivo, tipo: "template", parametro: this.nombreTemplateActivo + " - " + this.buttonForm.value.texto, codigo_parametro: null, direccion_parametro: null};
						this.services.insertNodeOutput(params2).subscribe(data3 => {
							console.log("inserto node output");
							this.outputs.push(this.nombreTemplateActivo + " - " + this.buttonForm.value.texto);
							this.reload();
						});
					}
					else if(typeof(this.buttonForm.value.link)!="undefined" && this.buttonForm.value.link.length == 0)
					{
						console.log("ENTRO ELSE IF");
						var params2 = {frases: [this.buttonForm.value.texto], nodo: this.nodoActivo, tipo: "template", parametro: this.nombreTemplateActivo + " - " + this.buttonForm.value.texto, codigo_parametro: null, direccion_parametro: null};
						this.services.insertNodeOutput(params2).subscribe(data3 => {
							console.log("inserto node output");
							this.outputs.push(this.nombreTemplateActivo + " - " + this.buttonForm.value.texto);
							this.reload();
						});
					}
					else
						this.reload();
				});
			});
		}
		else
		{
			let params = this.buttonForm.value;
			params["codigo_template"] = this.templateActivo;
			this.services.insertButtonTemplate(params).subscribe(data => {
				console.log(this.buttonForm.value);
				if(this.buttonForm.value.link==null)
				{
					console.log("ENTRO IF");
					var params2 = {frases: [this.buttonForm.value.texto], nodo: this.nodoActivo, tipo: "template", parametro: this.nombreTemplateActivo + " - " + this.buttonForm.value.texto, codigo_parametro: null, direccion_parametro: null};
					this.services.insertNodeOutput(params2).subscribe(data3 => {
						console.log("inserto node output");
						this.outputs.push(this.nombreTemplateActivo + " - " + this.buttonForm.value.texto);
						this.reload();
					});
				}
				else if(typeof(this.buttonForm.value.link)!="undefined" && this.buttonForm.value.link.length == 0)
				{
					console.log("ENTRO ELSE IF");
					var params2 = {frases: [this.buttonForm.value.texto], nodo: this.nodoActivo, tipo: "template", parametro: this.nombreTemplateActivo + " - " + this.buttonForm.value.texto, codigo_parametro: null, direccion_parametro: null};
					this.services.insertNodeOutput(params2).subscribe(data3 => {
						console.log("inserto node output");
						this.outputs.push(this.nombreTemplateActivo + " - " + this.buttonForm.value.texto);
						this.reload();
					});
				}
				else
				{
					this.reload();
				}
			});
		}
	}
	
	confirmarTemplate()
	{
		this.buttonForm.reset();
		this.templateForm.reset();
		this.templateActivo = -1;
		this.nombreTemplateActivo = "";
		this.reload();
	}
	
	saveTemplates()
	{     	
		console.log(this.outputs);
		this.board.addTemplate(this.nombreActivo, this.nodoActivo, this.data.bot, "template", this.outputs);
		this.dialogRef.close(true);
	}

	modifyTemplates() {
		console.log("modificar");
	}

	tabsel(evt): void {
		this.selected = evt;
	}

	onNoClick(event): void {
		this.dialogRef.close(event);
	}

}
