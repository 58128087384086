import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { MatDialog, MatTableDataSource, PageEvent, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Papa } from 'ngx-papaparse';
import { deleteElement } from '../delete/eliminar-element.component';
import { LocalSaveService } from '../local-save.service';
import { ServicesService } from '../services.service';
import { ComponentPageTitle } from '../page-title/page-title';
import { ValidatemenusService } from '../validatemenus.service';

import * as io from 'socket.io-client';
import Socket = io.Socket;
@Component({
	selector: 'app-bots',
	templateUrl: './bots.component.html',
	styleUrls: ['./bots.component.css']
})
export class BotsComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	isLoadingResults = false;
	botCount = 0;
	_estadofilter = -1;
	filter_search = "";
	bots = [];

	constructor(
		private router: Router,
		private localSave: LocalSaveService,
		public dialog: MatDialog,
		public componentPageTitle: ComponentPageTitle,
		public services: ServicesService,
		private validatemenu: ValidatemenusService
	) { }

	private socket: Socket;
	ngOnInit() {
		this.socket = io('http://movistarsv-fb.isoft-ste.com', { autoConnect: true });
		this.socket.connect();
		this.socket.on('feedback-bot', (mensajenuevo) => {
			console.log(mensajenuevo);
			let properties = mensajenuevo.mensaje.split('|');
			let auxindex = this.bots.map((bot) => { return bot.codigo_bot }).indexOf(parseInt(properties[1]));
			if (auxindex != -1) {
				this.bots[auxindex].estadorancher = properties[2];
				this.bots[auxindex].porcentajerancher = parseInt(properties[0]) * 12.5;
				this.bots[auxindex].modebar = 'determinate';
				this.bots[auxindex].tooltip = (parseInt(properties[0]) * 10) + '%';
				console.log(this.bots[auxindex].porcentajerancher);
				console.log(this.bots[auxindex].tooltip);
				if (properties[0] == 8) {
					console.log('MENSAJE ESTADO 8');
					setTimeout(() => {
						this.reload();
					}, 3000);
				}
			}
			// ---------------------------------------------------------------------------------

		});
		console.log(this.localSave.get('nombre_bot_seleccionado'));
		if (this.localSave.validate('nombre_bot_seleccionado')) {
			this.filter_search = this.localSave.get('nombre_bot_seleccionado');
			this.localSave.delete('nombre_bot_seleccionado');
			this.localSave.delete('generate');
			this.search();
		}
		this.componentPageTitle.title = 'Bots';
		this.reload();
	}

	filterestado() {
		this.reload();
	}

	reload() {
		this.isLoadingResults = true;
		var filters = [this.filter_search, this.paginator.pageIndex, this._estadofilter];
		this.services.getBots(filters).subscribe(data => {
			console.log(data);
			this.bots = data;
			this.services.getBotsCount([this.filter_search, this._estadofilter]).subscribe(data => {
				console.log(data);
				this.botCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}

	doAction(event, bot?) {
		if (event == "add") {
			this.ingresoBot();
		}
		else if (event == "modify") {
			this.modificarBot(bot);
		} else if (event == "edit") {
			this.localSave.set("codigo_bot_seleccionado", bot.codigo_bot);
			this.localSave.set("status_bot_seleccionado", bot.codigo_estado);
			this.localSave.set("nombre_bot_seleccionado", bot.nombre);
			this.router.navigate(['home/drag-drop']);
		} else if (event == "delete") {
			let dialogRef = this.dialog.open(deleteElement, {
				disableClose: true,
				data: { bot: bot }
			});
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.services.deleteBot({ codigo: bot.codigo_bot, justificacion: dialogRef.componentInstance.justificacion }).subscribe(data => {
						this.search();
					});
				}
			});
		} else if (event == "copy") {
			bot.nombre += " - copia";
			this.services.insertBot(bot).subscribe(data => {
				this.search();
			});
		}
	}

	ingresoBot() {
		let dialogRef = this.dialog.open(ingresoBot, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {}
		});
		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	modificarBot(bot) {
		let dialogRef = this.dialog.open(ingresoBot, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: { bot }
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	search() {
		this.filter_search = this.filter_search.trim();
		if (this.paginator.pageIndex > 0) {
			this.paginator.firstPage();
		}
		this.reload();
	}

	fnvalidatemenu(menu) {
		return this.validatemenu.permiso(menu);
	}

}

@Component({
	selector: 'ingresoBot',
	templateUrl: 'ingreso-bot.html',
	styles: ['.example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center !important;} table { width: 100%;}']
})

export class ingresoBot {
	botActivo = -1;
	nombreActivo = "";
	botForm = new FormGroup({
		codigo_bot: new FormControl(''),
		nombre: new FormControl('', [Validators.required, Validators.maxLength(20)]),
		descripcion: new FormControl('', [Validators.required, Validators.maxLength(300)]),
		im: new FormControl(1),
		json_rete: new FormControl('{}'),
		json_nodos: new FormControl('{}'),
		nodos: new FormControl(1),
		credentials: new FormControl('', [Validators.required]),
		nproyecto: new FormControl('', [Validators.required])
	});

	bot: any = [];
	pageEvent: PageEvent;
	pageIndex = 0;
	isLoadingResults = false;
	botCount = 0;
	displayedColumns = ["nombre", "descripcion", "token"];

	constructor(private router: Router, public dialogRef: MatDialogRef<ingresoBot>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private http: HttpClient, public snackBar: MatSnackBar, public servicios: ServicesService, private papa: Papa) {
		console.log(this.data)
		this.bot = this.data.bot;
		if (typeof (this.bot) != 'undefined') {
			this.botActivo = this.bot.codigo_bot;
			this.nombreActivo = this.bot.nombre;
			this.botForm.patchValue(this.bot);
		}
	}

	guardarBot() {
		this.servicios.insertBot(this.botForm.value).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.servicios.setBotState([resultado, 1]).subscribe(data => {
					this.botActivo = resultado;
					this.nombreActivo = this.botForm.value.nombre;
					this.dialogRef.close();
					this.localSave.set("codigo_bot_seleccionado", resultado);
					this.router.navigate(['home/drag-drop']);
				});
			}
			else {
				this.snackBar.open("Bot ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	modificarBot() {
		this.servicios.updateBot({ botData: this.botForm.value }).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.nombreActivo = this.botForm.value.nombre;
				this.dialogRef.close();
			}
			else {
				this.snackBar.open("Bot ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	getErrorMessage(element) {
		//console.log(element);
		if (element.hasError('required')) {
			return "Campo requerido";
		} else if (element.hasError('maxlength')) {
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		} else if (element.hasError('pattern')) {
			return "No se permiten espacios";
		}
	}
}